<template>
  <section class="section">
    <div class="container">
      <div v-if="requisitions">
        <div v-if="requisitions.length > 0">
          <el-table :data="requisitionTableData" :default-sort="{ prop: 'date', order: 'descending' }">
            <el-table-column prop="service" label="Service"></el-table-column>
            <el-table-column prop="date" label="Order Date" :formatter="formatDate" sortable></el-table-column>
            <el-table-column prop="amount" label="Amount" :formatter="formatAmount" sortable></el-table-column>
            <el-table-column prop="status" label="Status" :formatter="formatStatus"></el-table-column>
            <el-table-column prop="outputNote" label="Note"></el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <router-link :to="`/order/${scope.row.id}`">
                  <el-button type="text">View Order</el-button>
                </router-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-else>
          Looks like you don't have any requisitions yet.
          <router-link class="has-text-weight-bold" :to="{ name: 'home' }">Check out the market.</router-link>
        </div>
      </div>
      <div v-else>Loading...</div>
    </div>
  </section>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify'
import { STATUS } from '@/graph-constants'
// import { listRequisitions } from '@/graphql/queries'
import { reqsByOwner } from '@/api/graphql/ServiceHelpers'
import { timeFormat } from 'd3'

const requisitionDateTimeFormat = timeFormat('%b %d at %-I:%M %p')

const requisitionTableMap = (requisition) => ({
  service: requisition.service.title,
  date: new Date(requisition.createdAt),
  amount: requisition.amount,
  status: requisition.status[requisition.status.length - 1].value,
  outputNote: requisition.outputNote,
  id: requisition.id,
  subscription: requisition.subscription,
})

export default {
  name: 'Requisitions',
  data() {
    return {
      requisitions: null,
      requisitionTableData: null,
    }
  },
  async created() {
    const res = await API.graphql(
      graphqlOperation(reqsByOwner, {
        owner: this.$store.state.user.username,
        limit: 1000,
      })
    )
    // const res = await API.graphql(
    //   graphqlOperation(listRequisitions, {
    //     limit: 1000,
    //     filter: {
    //       owner: { eq: this.$store.state.user.username },
    //     },
    //   })
    // )
    // this.requisitions = res.data.listRequisitions.items
    this.requisitions = res.data.reqsByOwner.items
    this.requisitionTableData = this.requisitions.map(requisitionTableMap)
  },
  methods: {
    formatDate(row, col, cellValue) {
      return requisitionDateTimeFormat(cellValue)
    },
    formatAmount(row, col, cellValue) {
      if (row.subscription) {
        return 'Subscription'
      }
      if (cellValue) {
        return `$${(cellValue / 100).toFixed(2)}`
      }
      return '--'
    },
    formatStatus(row, col, cellValue) {
      const map = {
        [STATUS.CREATED]: 'Order initiated',
        [STATUS.RECEIVED]: 'Order received',
        [STATUS.PROCESSING]: 'Order processing',
        [STATUS.FULFILLED]: 'Fulfilled',
        [STATUS.REJECTED]: 'Seller Rejected',
        [STATUS.NOT_RECEIVED]: 'Seller Error',
      }
      return map[cellValue] || ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
